import appModel from '../../../models/app-model'
import { useSnapshot } from 'valtio'
import { useEffect, useState } from 'react'
import { getBusinessCentralURLParams } from '../../../utils/general'
import CustomerWorkFlow from './customerWorkflow'
import CustomModal from '../../../components/customMessageModal/customMsgModal'
import ProductionSizeModal from './productionSizeModal'
import Dropdown from '../../../components/dropdown/dropdownMenu'
import Spinner from './spinner'
import NotesModal from './notesModal'
import NotesEmbroideryModal from './notesEmbroideryModal'
import { DesignDoc } from '../../../types/api-types'
import { InternalArtStates, InternalArtStatesDescriptions } from '../../../types/art-status'
import { addDesignComment, approveDesign, handleRejectProof, setProofToReady, updateDesignArtStatus, updateDesignDetails } from '../../../models/app-actions'
import WEINotesModal from './weiNotesModal'
import OneTimeChargeModal from './oneTimeChargesModal'
import ChangeLayerName from './changeLayerNamesModal'
import { editableProperties } from '../../../utils/artDetailsUtils'
//import { redirectToDWS } from '../../../utils/cart'
import CommentBox from '../../../components/commentBox/commentBox'
import { wizardDocArchive } from '../../../api/backend'
import SewDiskFeesModal from './sewDiskFeesModal'
import { WEIProductTypes } from '../../../types/product-types'
import { addAuthorizationHeader } from '../../../api/network'
import { getBackendApiUrl } from '../../../config'

// Styles
import styles from './itemWorkFlow.module.css'

interface ChatProps {
  designId: string;
  designStatus: string;
  activeConversationId: number;
  adminMode: boolean;
  archived: boolean;
}

function findAllProductionUrls(
  obj: any,
  result: Record<string, string> = {}
): Record<string, string> {
  if (typeof obj !== "object" || obj === null) return result;

  if ("production_url" in obj && "pattern" in obj && typeof obj.pattern === "string") {
    result[obj.pattern] = obj.production_url;
  }

  for (const key in obj) {
    if (typeof obj[key] === "object") {
      findAllProductionUrls(obj[key], result);
    }
  }

  return result;
}

export function ItemWorkFlow(props: ChatProps): JSX.Element {
  const appSnap = useSnapshot(appModel);
  const wizardData = appSnap.activeDesignDoc?.wizardData;
  const [error, setError] = useState<any>(null);
  const customerId = appSnap.customerId;
  const isCustomerView = !props.adminMode
  const adminId = appSnap.adminId;
  const commentUserId = customerId || adminId;
  const [updatingConversation, setUpdatingConversation] = useState(false);
  const [commentValue, setCommentValue] = useState('');
  const [isProductionSizeModalOpen, setProductionSizeModalOpen] = useState<boolean>(false);
  const [isNotesModalOpen, setNotesModalOpen] = useState<boolean>(false);
  const [isEmbroideryNotesModalOpen, setEmbroideryNotesModalOpen] = useState<boolean>(false);
  const [addSewDiskFeesModalOpen, setAddSewDiskFeesModalOpen] = useState<boolean>(false);
  const [isOneTimeChargeModalOpen, setIsOneTimeChargeModalOpen] = useState<boolean>(false);
  const businessCentralParams = getBusinessCentralURLParams();
  const [openCustomerModal, setOpenCustomerModal] = useState<boolean>(false);
  const [openProofReadyModal, setOpenProofReadyModal] = useState<boolean>(false);
  const [openChangeLayerNameModal, setOpenChangeLayerNameModal] = useState<boolean>(false);
  const [showEditLayerNamesOption, setShowEditLayerNamesOption] = useState<boolean>(false);
  const [resetComment, setResetComment] = useState<boolean>(false);
  const [processing, setIsProcessing] = useState<boolean>(false);
  //const canComplete = !!appSnap.activeDesignDoc?.productionArtworkPreview?.length; //store.wizardDoc.activeWizardDoc?.statusID != OrderStatuses.Approved && !store.wizardDoc.activeWizardDoc?.lineID ;
  //const actionMenuEnabled = store.wizardDoc.activeWizardDoc?.statusID != OrderStatuses.Approved ;
  const isApproved = appSnap.activeDesignDoc?.statusID == InternalArtStates.APPROVED
  const isReview = appSnap.activeDesignDoc?.statusID == InternalArtStates.REVIEW
  const canApprove = appSnap.activeDesignDoc?.statusID == "PEND_C" && !!appSnap.activeDesignDoc?.productionArtworkPreview?.length;
  const [isLoading, setIsLoading] = useState(false);
  const [isWEINotesModalOpen, setWEINotesModalOpen] = useState<boolean>(false);
  const isProduction = businessCentralParams["SourceType"] == "Production";
  const isWEI = appSnap.activeDesignDoc?.docType == "WizardDesign" && WEIProductTypes.includes(appSnap.activeDesignDoc.wizardID)
  const isCustomProduct = appSnap.activeDesignDoc?.docType == "WizardDesign" && appSnap.activeDesignDoc.wizardID == "CustomProduct";
  const allowSewDiskFees = appSnap.activeDesignDoc?.docType == "WizardDesign" && wizardData.sewFile === "yes" && false;
  const [purchased, setPurchased] = useState<boolean>(false);
  const existArtworkProof = Boolean(appSnap.activeDesignDoc?.productionArtworkPreview?.length);

  useEffect(() => {
    if (customerId && appSnap.activeDesignDoc) {
      fetch(`${getBackendApiUrl()}/design-purchases/${appSnap.activeDesignDoc.id}`, {
        method: 'GET',
        headers: addAuthorizationHeader({
          'Content-Type': 'application/json'
        })
      }).then(async (purchasesResp) => {
        if (!purchasesResp.ok) throw new Error("Error getting purchase data " + await purchasesResp.text())
        const purchasesRespJson: { purchased: boolean } = await purchasesResp.json()
        console.log(`Design id ${appSnap.activeDesignDoc?.id} was${purchasesRespJson.purchased ? '' : ' not'} marked as purchased`)
        setPurchased(purchasesRespJson.purchased)
      }).catch(err => {
        throw err
      });
    }
  }, [customerId])

  useEffect(() => {
    if (props.adminMode) {
      const editable = hasEditableProperties(wizardData, editableProperties)
      setShowEditLayerNamesOption(editable);
    }
  }, [props.adminMode])

  const handleCommentChange = (comment: string) => {
    setCommentValue(comment);
  };

  const hasEditableProperties = (data: any, properties: string[]): boolean => {
    return properties.some(property => property in data);
  };

  const handleSaveClick = async (
    userToken: string,
    message: string,
    designId: string,
    lastMessageId: number,
    statusID: string,
    lineNo: number,
    updateStatus?: boolean) => {
    if (!updatingConversation) {
      try {
        setIsLoading(true);
        setUpdatingConversation(true);

        await addDesignComment({
          userToken,
          message,
          lastMessageId,
          statusID,
          lineNo,
          docNo: businessCentralParams.DocNo,
          designId: designId,
          updateStatus
        })

        // Reset the textarea value
        setResetComment(prev => !prev);
        setUpdatingConversation(false);
      }
      catch (e) {
        setIsLoading(false);
        setError(e);
      }
      finally {
        setIsLoading(false);
      }
    }
  };

  // Handle dropdown click options 
  async function archive() {
    try {
      setIsLoading(true);

      await wizardDocArchive(props.designId);
      await updateDesignDetails();

      setError(null);
    } catch (error) {
      console.error(error);
      setError(error);
    }
    finally { setIsLoading(false) }
  }

  function changeSize() {
    setProductionSizeModalOpen(true);
  }

  function openWEINotes() {
    setWEINotesModalOpen(true)
  }

  function addNotes() {
    setNotesModalOpen(true);
  }

  function addSewFileInternalEmbroideryNotes() {
    setEmbroideryNotesModalOpen(true);
  }

  function addSewDiskFees() {
    setAddSewDiskFeesModalOpen(true);
  }

  function approveForCustomer() {
    setOpenCustomerModal(true);
  }

  function changeLayerName() {
    setOpenChangeLayerNameModal(true);
  }

  async function assignOrderStatus(newStatus: InternalArtStates) {
    try {
      setIsLoading(true);

      await updateDesignArtStatus(
        props.designId,
        newStatus
      );

      setError(null);
    } catch (error) {
      console.error(error);
      setError(error);
    }
    finally { setIsLoading(false) }
  }

  async function assignToCSR() {
    await assignOrderStatus(InternalArtStates.PEND_S_CSR);
  }

  async function assignToDigitizing() {
    await assignOrderStatus(InternalArtStates.PEND_S_OUT);
  }

  async function assignToArtTeam() {
    await assignOrderStatus(InternalArtStates.PEND_S_ART);
  }

  async function assignToCustomer() {
    await assignOrderStatus(InternalArtStates.PEND_C);
  }

  async function handleRejectProofClick() {
    setIsLoading(true);
    const id = props.designId;

    try {
      await handleRejectProof(id, adminId as string)
      updateDesignDetails();
      setError(null);

    } catch (err) {
      console.log(err);
      setError(err);
    } finally { setIsLoading(false) }
  }

  // function addAlternativeDesign() {
  //   redirectToDWS(appSnap.activeDesignDoc?.id as string, appSnap.activeDesignDoc?.customerID as string, true, true);
  // }

  const confirmApproveArt = async (comment?: string) => {
    try {
      setIsProcessing(true);
      await approveDesign(props.designId);

      // Save comment without changing status
      if (comment)
        saveComment(comment, false);

      updateDesignDetails();
      setError(null);
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
      setIsProcessing(false);
      setOpenCustomerModal(false);
    }
  }

  const confirmProofIsReady = async () => {
    try {
      setIsProcessing(true)
      await setProofToReady(props.designId)
      updateDesignDetails();
    } catch (err) {
      console.log(err);
    } finally {
      setIsProcessing(false);
      setOpenProofReadyModal(false);
    }
  }

  const handleSizeChangeSuccess = (comment: string) => {
    if (comment)
      saveComment(comment);

    setProductionSizeModalOpen(false);
    updateDesignDetails();
  }

  const handleOneTimeChargeSucess = (comment: string) => {
    if (comment)
      saveComment(comment);
  }

  const handleSewDiskFeesSucess = (comment: string) => {
    if (comment)
      saveComment(comment);
  }

  const handleEditLayerSuccess = (comment: string) => {
    if (comment)
      saveComment(comment);

    setOpenChangeLayerNameModal(false);
    updateDesignDetails();
  }

  const saveComment = (comment: string, updateStatus?: boolean) => {
    const nextStatus = adminId ? "PEND_C" : "PEND_S_ART";
    const lineNo = Number(businessCentralParams?.DocLineNo);
    handleSaveClick(commentUserId as string
      , comment
      , props.designId
      , props.activeConversationId
      , nextStatus
      , lineNo
      , updateStatus);
  }

  const patternBuildersprodUrls = findAllProductionUrls(wizardData);

  const menuItems = [
    ...(!isCustomProduct ? [{ text: 'Modify Size', onItemClicked: changeSize }] : []),
    //...(!isLNProof && !isCustomProduct ? [{ text: 'Edit Art Charges', onItemClicked: editOneTimeCharges }] : []),
    ...(!isCustomProduct ? [{ text: 'Edit Layer Names', onItemClicked: changeLayerName, disabled: !showEditLayerNamesOption }] : []),
    ...(!isCustomProduct ? [{ text: 'Approve on behalf of Customer', onItemClicked: approveForCustomer, disabled: !canApprove }] : []),
    { text: 'Add Internal Notes', onItemClicked: addNotes },
    { text: 'Add Internal Embroidery Notes', onItemClicked: addSewFileInternalEmbroideryNotes },
    ...(allowSewDiskFees && !purchased ? [{ text: 'Add Sew Disk Fees', onItemClicked: addSewDiskFees }] : []),
    ...(isWEI ? [{ text: 'Add Notes for WEI products', onItemClicked: openWEINotes }] : []),
    ...((isWEI && !isApproved && existArtworkProof) ? [{ text: 'Reject Proof', onItemClicked: handleRejectProofClick }] : []),
    ...(props.adminMode && Object.keys(patternBuildersprodUrls).length > 0 ? Object.entries(patternBuildersprodUrls).map(([key, url]) => ({
      text: `Download ${key} pattern`,
      onItemClicked: () => window.open(url, "_blank"),
    })) : []),
    ...(!isProduction ? [props.archived ? { text: 'Unarchive', onItemClicked: archive } : { text: 'Archive', onItemClicked: archive }] : []),
    ...(!isCustomProduct ? [{ text: 'Assign to CSR', onItemClicked: assignToCSR, style: { background: '#F9FAFB' } }] : []),
    ...(!isCustomProduct ? [{ text: 'Assign to Digitizing', onItemClicked: assignToDigitizing, style: { background: '#F9FAFB' } }] : []),
    { text: 'Assign to Art Team', onItemClicked: assignToArtTeam, style: { background: '#F9FAFB' } },
    { text: 'Assign to Customer', onItemClicked: assignToCustomer, style: { background: '#F9FAFB' } },
    // { text: 'Add Alternative Design', onItemClicked: addAlternativeDesign, style: { background: '#F9FAFB' } },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        {(props.adminMode) &&
          <>
            <div className={styles.titleWrapper}>
              <span className={styles.title}>Item Status : {appSnap.activeDesignDoc?.statusID ? InternalArtStatesDescriptions[appSnap.activeDesignDoc?.statusID].description : ''}</span>
              <Spinner spinning={isLoading}></Spinner>
            </div>
            <div className={styles.itemsWrapper}>

              {/* <button
                type="button"
                disabled={!canComplete}
                className={`${canComplete ? buttonClasses : disabledButtonClass}`}
                onClick={() => setOpenProofReadyModal(true)}
              >
                Proof Ready
              </button> */}

              <Dropdown buttonText="Actions" menuItems={menuItems} />

              {/* Size Modal */}
              <ProductionSizeModal
                isOpen={isProductionSizeModalOpen}
                onClose={() => setProductionSizeModalOpen(false)}
                onSizeChangeSuccess={(e) => { handleSizeChangeSuccess(e) }}
                document={appSnap.activeDesignDoc as DesignDoc}
                isCustomerView={isCustomerView}
              />

              {/* Size Modal */}
              <NotesModal
                isOpen={isNotesModalOpen}
                onClose={() => setNotesModalOpen(false)}
                documentId={appSnap.activeDesignDoc?.id}
              />

              {/* Size Modal */}
              <NotesEmbroideryModal
                isOpen={isEmbroideryNotesModalOpen}
                onClose={() => setEmbroideryNotesModalOpen(false)}
                documentId={appSnap.activeDesignDoc?.id}
                currentNote={appSnap.activeDesignDoc?.wizardData?.sewFileInternalEmbroideryNotes}
              />

              {/* Customer confirm Modal */}
              <CustomModal
                isOpen={openCustomerModal}
                onClose={() => setOpenCustomerModal(false)}
                message="Approve on Behalf of the Customer?"
                onConfirm={(e) => { confirmApproveArt(e) }}
                processing={processing}
                confirmButtonText="Approve"
                showCancelButton={false}
                userId={commentUserId as string}
                isCustomerView={isCustomerView}
              />

              {/* Complete confirm Modal */}
              <CustomModal
                isOpen={openProofReadyModal}
                onClose={() => setOpenProofReadyModal(false)}
                message="Send to the customer for approval?"
                onConfirm={confirmProofIsReady}
                processing={processing}
                confirmButtonText="Confirm"
                showCancelButton={false}
                isCustomerView={isCustomerView}
              />

              {/* WEI notes Modal */}
              <WEINotesModal
                isOpen={isWEINotesModalOpen}
                onWEINotesUpdateSuccess={() => { setWEINotesModalOpen(false); updateDesignDetails(); }}
                onClose={() => { setWEINotesModalOpen(false) }}
                document={appSnap.activeDesignDoc as DesignDoc}
              />

              {/* One Time Additional charge modal */}
              <OneTimeChargeModal
                isOpen={isOneTimeChargeModalOpen}
                onClose={() => { setIsOneTimeChargeModalOpen(false) }}
                document={appSnap.activeDesignDoc as DesignDoc}
                userId={commentUserId as string}
                onOneTimeChargeSuccess={(e) => { handleOneTimeChargeSucess(e) }}
                isCustomerView={isCustomerView}
              />

              {/* Edit Layer Name modal */}
              <ChangeLayerName
                isOpen={openChangeLayerNameModal}
                onClose={() => setOpenChangeLayerNameModal(false)}
                onSuccess={(e) => { handleEditLayerSuccess(e); }}
                wizardData={wizardData}
                docId={appSnap.activeDesignDoc?.id}
                isCustomerView={isCustomerView}
              />

              {/* Add Sew Disk Fees Modal */}
              <SewDiskFeesModal
                isOpen={addSewDiskFeesModalOpen}
                onClose={() => { setAddSewDiskFeesModalOpen(false) }}
                document={appSnap.activeDesignDoc as DesignDoc}
                addSewDiskFeesSuccess={(e) => { handleSewDiskFeesSucess(e) }}
                isCustomerView={isCustomerView}
              />

            </div>
          </>
        }
        {(!props.adminMode) &&
          <>
            <CustomerWorkFlow
              itemId={props.designId}
              docType={appSnap.activeDesignDoc?.docType as string}
              wizardType={appSnap.activeDesignDoc?.wizardID as string}
              wasPurchased={purchased} />
          </>
        }
        {error && <div>{error.response.data.error}</div>}
        <ul role="list">
          {/* New comment form */}
          {(commentUserId && !isApproved && !isReview) && (
            <div className="">
              <form action="#" className="relative flex-auto">
                <div className={styles.commentWrapper}>
                  <label htmlFor="comment" className="sr-only">
                    {isCustomerView ? `Add your comment for the art team...` : `Add your comment for the customer...`}
                  </label>
                  <CommentBox
                    onCommentChange={handleCommentChange}
                    isCustomerView={isCustomerView}
                    resetComment={resetComment} />
                  <div className={styles.buttons}>
                    <button
                      type="button"
                      disabled={updatingConversation || isApproved}
                      className={styles.button}
                      onClick={() => {
                        const nextStatus = adminId ? "PEND_C" : "PEND_S_ART";
                        const lineNo = Number(businessCentralParams?.DocLineNo);
                        handleSaveClick(commentUserId
                          , commentValue
                          , props.designId
                          , props.activeConversationId
                          , nextStatus
                          , lineNo);
                      }
                      }
                    >
                      Send
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </ul>
      </div>
    </>
  );
}