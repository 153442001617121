import { getWizardDocById } from "../api/backend";
import { DesignDoc } from "../types/api-types";
import { IProdOrderLineItem } from "../types/iprod-order";


export async function queryUCMItems(productionData: IProdOrderLineItem[]) {

  const docsPromises = productionData.map((prodOrder) => {
    const docId = prodOrder?.designdocidvel;
    return getWizardDocById(docId);
  });

  const docs = await Promise.all(docsPromises);

  const newProdOrdersData = productionData.map((prodOrder) => {
    const docId = prodOrder?.designdocidvel;
    const matchedDoc = docs.find((doc) => doc.id == docId || doc.internalID == docId) as DesignDoc;

    return {
      docId,
      docNo: prodOrder.prod_orderno_,
      wizardData: matchedDoc,
      originalArtwork: matchedDoc?.originalArtwork,
      originalDocID: matchedDoc?.originalDocID,
      productionArtwork: matchedDoc?.productionArtwork,
      productionArtworkPreview: matchedDoc?.productionArtworkPreview,
      totalQuantity: matchedDoc?.wizardData?.quantities?._ || 0,
      quantity: prodOrder.quantity,
      printQuantity: prodOrder.quantity,
      size: {
        height: matchedDoc?.wizardData?.artHeight || 0,
        width: matchedDoc?.wizardData?.artWidth || 0
      },
      shipDate: prodOrder.duedate,
      itemNo: prodOrder.itemno_,
      imageLoaded: false,
      lineItemsCount : prodOrder.lineItemsCount
    };
  });

  return newProdOrdersData
}
