import { getWizardDocById } from "../api/backend";
import { getBackendApiUrl, getDWSUrl, getLNUrl, isInternal } from "../config";
import { addAuthorizationHeader, getAdminId, getAdminToken, getCustomerToken } from "../api/network";
//import { generateImageSrcFromArtwork } from "./general";

export async function redirectToDWS(wizardDocId: string, customerId: string, editMode: boolean = false, alternativeDesign = false ) {
    const headers = addAuthorizationHeader({
        'Content-Type': 'application/json'
    });

    const backendUrl = getBackendApiUrl();
    const innerBackendUrl = backendUrl?.match(/:\/\/([^/]+)/)?.[1] || "";
    

    const sanaDocResp = await fetch(`${backendUrl}/DesignClone`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            id: wizardDocId
        })
    });

    if (!sanaDocResp.ok) throw new Error("Failed to clone doc before adding to cart");

    const respJson = await sanaDocResp.json();
    const clonedDocId = respJson.id;
    const DWS_URL = getDWSUrl();

    const customerToken = getCustomerToken();
    const adminToken = getAdminToken();
    const adminAvailable = !!adminToken;
    const adminId = getAdminId();
    const adminIdAvailable = !!adminId;
    const adminIdPart = adminIdAvailable ? `&AdminId=${adminId}` : ""
    const internal = isInternal() ? `&internal=true` : ""

    const redirectUrl = DWS_URL.indexOf('localhost') > -1
    ? `http://${DWS_URL}/index.html?apiUrl=${innerBackendUrl}&customerId=${customerId}&designId=${clonedDocId}&edit=${editMode}&alternativeDesign=${alternativeDesign}&${adminAvailable ? `adminToken=${adminToken}` : `customerToken=${customerToken}`}${adminIdPart}${internal}`
    : `https://${DWS_URL}/index.html?apiUrl=${innerBackendUrl}&customerId=${customerId}&designId=${clonedDocId}&edit=${editMode}&alternativeDesign=${alternativeDesign}&${adminAvailable ? `adminToken=${adminToken}` : `customerToken=${customerToken}`}${adminIdPart}${internal}`;
    window.location.replace(redirectUrl);
}

export async function redirectToLNTransfer(wizardDocId: string, customerId: string, wizardType: string, isLNProof: boolean) {
    const headers = {
        'Content-Type': 'application/json'
    };

    const lnEditParams = await fetch(`${getBackendApiUrl()}/getEditParams`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            docId: wizardDocId
        })
    });

    if (!lnEditParams.ok) throw new Error("Failed to get edit parameters for LN wizard");

    const editUrlData = await lnEditParams.json();
    const LN_URL = getLNUrl();

    const redirectUrl = new URL(LN_URL.indexOf('localhost') > -1
        ? `http://${LN_URL}/index.html?customerId=${customerId}&wizardType=${wizardType}&${editUrlData.urlParams}&customerToken=${getCustomerToken()}`
        : `https://${LN_URL}/index.html?customerId=${customerId}&wizardType=${wizardType}&${editUrlData.urlParams}&customerToken=${getCustomerToken()}`);
    redirectUrl.searchParams.set("ProofOnly", "0");
    redirectUrl.searchParams.set("pp", "");
    redirectUrl.searchParams.set("qty", "");
    redirectUrl.searchParams.set("proofemail", "");
    redirectUrl.searchParams.set("ppr", "");
    redirectUrl.searchParams.set("reorder", "1");
    redirectUrl.searchParams.set("originalDocId",wizardDocId);

    let isLNProofAndWasPurchased = false;

    if(isLNProof) {
      const purchasesResp = await fetch(`${getBackendApiUrl()}/design-purchases/${wizardDocId}`, {
        method: 'GET',
        headers: addAuthorizationHeader({
          'Content-Type': 'application/json'
        })
      })
      if (!purchasesResp.ok) throw new Error("Error getting purchase data " + await purchasesResp.text())
      const purchasesRespJson: { purchased: boolean } = await purchasesResp.json()
      isLNProofAndWasPurchased = purchasesRespJson.purchased
    }

    if(!isLNProof || isLNProofAndWasPurchased) {
        ["disk","stitchq", "stitch2q", "stitch3q", "StitchType", "StitchType2", "StitchType3", "SewDisk", "DiskFormat", "szDiskCode", "DiskWSKU", "szStitchType", "szStitch", "szDiskFormat", "szDiskType", "szSewDisk", "sewDiskEmail"].forEach(x => {
            if(redirectUrl.searchParams.get(x)) redirectUrl.searchParams.set(x, "");
        })
        
        if(redirectUrl.searchParams.get("namedrop") == "yes") {
          redirectUrl.searchParams.set("namedrop", "no")
          redirectUrl.searchParams.set("namedropposition", "")
          redirectUrl.searchParams.set("namedroptext", "")
          redirectUrl.searchParams.set("namedroptype", "")
          redirectUrl.searchParams.set("tailtype", "Plain")
        }
    }

    window.location.replace(redirectUrl.toString());
}

const addProofToCart = async function(wizardDocId: string) {

    const wizardDoc = await getWizardDocById(wizardDocId);

    const baseUrl = getBackendApiUrl();

    const priceResp = await fetch(`${baseUrl}/DesignPrices`,
      {
        method: 'POST',
        'headers': addAuthorizationHeader({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          id: wizardDocId
        })
      }
    )
    
    if(!priceResp.ok) throw new Error("Failed to get prices");
    
    const designPricesJson = await priceResp.json()
    const [pricing,...otherPrices] = Array.isArray(designPricesJson) ? designPricesJson : [designPricesJson] ;

    const token = pricing.token ;
    const totalPrice = pricing.price ;
    const variant = pricing.variante ;
    const itemNo = pricing.itemNo ;
    const configEditUrl = window.location.href ;
    const imageUrl = pricing.imageUrl//wizardDoc.originalArtwork && wizardDoc.originalArtwork[0] ? generateImageSrcFromArtwork(wizardDoc.originalArtwork[0], "" ) : null; 

    const itemGroup = { 
        items: [
            { 
                productId: pricing.itemNo, 
                quantity: 1, 
                token, 
                itemNo: itemNo, 
                variantId: variant, 
                price: totalPrice, 
                imageUrl,
                uomId: "Each", 
                ...wizardDoc.wizardData, 
                configEditUrl 
            }
        ] 
    };

    for (const otherPrice of otherPrices) {
        itemGroup.items.push({
          productId: otherPrice.itemNo, uomId: otherPrice.UofM, ...otherPrice
        })
      }


    window.parent.postMessage(itemGroup, '*');
    console.log('parent.postMessge', JSON.stringify(itemGroup, null, 2))
}

const addItemToCart = async function(wizardDocId: string, customerId: string, docType: string, wizardType: string, isLNProof: boolean) {
    const addedToCartNotification = await fetch(`${getBackendApiUrl()}/DesignAddedToCart`, {
        method: 'POST',
        headers: addAuthorizationHeader({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            designId: wizardDocId
        })
    });

    if (!addedToCartNotification.ok) throw new Error("Failed to notify add to cart");

    if (docType === "LNTransfer") {
        await redirectToLNTransfer(wizardDocId, customerId, wizardType, isLNProof);
    } else if ((docType === "WizardDesign") || (docType === "EasyView")) {
        await redirectToDWS(wizardDocId, customerId, false);
    }
};

export const addToCart = async function(wizardDocId: string, customerId: string, docType: string, wizardType: string, isLNProof: boolean, statusId? : string) {
    if (statusId === "REVIEW") {
        await addProofToCart(wizardDocId);
    } else {
        await addItemToCart(wizardDocId, customerId, docType, wizardType, isLNProof);
    }
}
