// Styles
import styles from './cardValues.module.css';

function isValidUrl(value: string): boolean {
  try {
    new URL(value);
    return true;
  } catch {
    return false;
  }
}

const DownloadLink: React.FC<{ url: string }> = ({ url }) => {
  const maxLength = 30;
  const truncatedUrl = url.length > maxLength ? url.slice(0, maxLength) + "..." : url;

  return (
    <a href={url} download target="_blank" rel="noopener noreferrer" title={url} className="text-blue-600 underline">
      {truncatedUrl}
    </a>
  );
};


export const BooleanValue = ({ value }: { value: boolean }) => {
  return <span>{value ? 'Yes' : 'No'}</span>;
};

export const StringValue = ({ value }: { value: string }) => {
  return <span>{value}</span>;
};

export const ArrayValue = ({ value }: { value: any[] }) => {
  if (!value || value.length === 0) return null;

  return (
    <>
      {value.map((item, index) => (
        <div key={index}
          className={`${index !== value.length - 1 ? styles.borderBottom : ''}`}>
          {typeof item === 'string' ? (
            <StringValue value={item} />
          ) : typeof item === 'object' ? (
            <ObjectValue value={item} fromArray={true} />
          ) : typeof item === 'boolean' ? (
            <BooleanValue value={item} />
          ) : typeof item === 'number' ? (
            <span>{`- ${item}`}</span>
          ) : null}
        </div>
      ))}
    </>
  );
};

export const splitCamelCase = (str: string) => {
  if (!str) return str;
  const result = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  return result.replace(/\b\w/g, char => char.toUpperCase());
};

export const ObjectValue = ({ value, fromArray }: { value: Record<string, any>, fromArray?: boolean }) => {
  if (!value || typeof value !== 'object') {
    return null;
  }

  const entries = Object.entries(value);
  const totalItems = entries.length;

  return (
    <>
      {entries.map(([key, val], index) => {
        if (Array.isArray(val) && val.length === 0) return null;

        return (
          <div key={index} className={[styles.treeWrapper, fromArray ? styles.mLeft : ''].join(' ')}>
            <span className={styles.treeKey}>
              <span className={styles.dash}>- </span>
              {splitCamelCase(key)}:
            </span>
            <span className={styles.treeValue}>
              {typeof val === 'string' ? (
                isValidUrl(val) ? (
                  <DownloadLink url={val} />
                ) : (
                  <StringValue value={val} />
                )
              ) : typeof val === 'boolean' ? (
                <BooleanValue value={val} />
              ) : typeof val === 'object' ? (
                Array.isArray(val) ? (
                  <ArrayValue value={val} />
                ) : (
                  <ObjectValue value={val} />
                )
              ) : (
                String(val)
              )}
            </span>
            {index < totalItems - 1 && <span className={styles.printVisible}>, </span>}
          </div>
        );
      })}
    </>
  );
};